import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import MenuHamburger from '../images/inlinesvgs/NavigationHamBurger.svg'
import TransitionLink from 'gatsby-plugin-transition-link'
import Link from 'gatsby-plugin-transition-link'
import Wsplogosvg from '../images/inlinesvgs/wsp.svg'
import { ptnewan as ptdrip } from './pagetransition'
import Fullwsplogocon from '../images/inlinesvgs/fullwsplogocon.svg'
import { ptportal } from './ptportal'

import anime from 'animejs'
// import { TransitionState } from "gatsby-plugin-transition-link";

const Header = () => {
  // var pt_timeline_drip;
  useEffect(() => {
    document.querySelector('#dropdownsvg').onclick = function () {
      document.querySelector('#dropdownsvg').classList.toggle('cross')
      document.querySelector('.main-menu').classList.toggle('active-open')
    }
    let header_links = document.querySelectorAll('header a')
    document.getElementsByTagName('header')[0].getElementsByTagName('a')
    header_links.forEach(item => {
      item.addEventListener('click', e => {
        // console.log(`Clicked ${e.target}`)
        document.querySelector('#dropdownsvg').classList.contains('cross') &&
          document.querySelector('#dropdownsvg').classList.remove('cross')
        document
          .querySelector('.main-menu')
          .classList.contains('active-open') &&
          document.querySelector('.main-menu').classList.remove('active-open')
      })
    })
    // console.log(`All The Links On Header ${[...header_links]}`)
    anime.set(
      '.ptportal-con',
      {
        opacity: 0
      } // start frame
    )
    anime.set(
      '.left-layer--1',
      {
        clipPath: 'ellipse(0vh 0vh at 0% 0%)'
      } // start frame
    )
    anime.set(
      '.left-layer--3',
      {
        clipPath: 'ellipse(0vh 0vh at 0% 0%)'
      } // start frame
    )

    anime.set(
      '.pt-text p:first-child span',
      {
        translateY: '0%',
        translateX: '-40%',
        rotate: '45deg',
        opacity: 0
      } // start frame
    )
    anime.set(
      '.pt-text p:last-child span',
      {
        translateY: '7%',
        translateX: '47%',
        rotate: '45deg',
        opacity: 0
      } // start frame
    )
  }, [])

  const OnClick = event => {
    // alert(event);
  }

  const data = useStaticQuery(graphql`
    query MyQuery {
      wpgraphql {
        menus(where: { location: PRIMARY }) {
          edges {
            node {
              menuItems {
                nodes {
                  label
                  path
                }
              }
            }
          }
        }
      }
    }
  `)

  const primaryMenu = data.wpgraphql.menus.edges[0].node.menuItems.nodes
  // data.wpgraphql.menus !== null
  //   ? data.wpgraphql.menus.edges[0].node.menuItems.nodes
  //   : []

  // const primaryMenu = data.wpgraphql.menus.edges[0].node.menuItems.nodes

  return (
    <header className='header-container flex '>
      <div className='header-con-in-f w-full f-bold flex  justify-between px-2 xl:px-24 mx-auto bg-white items-center'>
        <div className='sitetitle w-3/4 md:w-72 lg:w-2/6 xl:w-1/5 text-left pl-4 lg:pl-2 xl:pl-0'>
          <TransitionLink
            to='/'
            exit={{
              length: 1,
              delay: 0,
              appearAfter: 1,
              zIndex: 2,
              trigger: ({ exit, node }) =>
                ptdrip({ exit, node, direction: 'out' })
            }}
            entry={{
              length: 1,
              delay: 0,
              appearAfter: 1,
              trigger: ({ exit, node }) =>
                ptdrip({ exit, node, direction: 'in' })
            }}
          >
            <div className='wsp-site-title-con h-font'>
              {/* <span>Web</span>
              <span className=''>S</span>
              <span className='wsp-logo-show'>
                <Wsplogosvg />
              </span>
              <span>olverPro</span> */}
              <Fullwsplogocon />
            </div>
          </TransitionLink>
        </div>
        <div className='primaryMenu w-1/4 lg:w-4/5'>
          <label for='menu-toggle' className='pointer-cursor lg:hidden block'>
            <div id='dropdownsvg' onClick={OnClick} className='ml-auto'>
              <MenuHamburger />
            </div>
          </label>
          <input className='hidden' type='checkbox' id='menu-toggle' />

          <div className='w-full lg:flex justify-end main-menu absolute left-0  lg:static border-t-2 border-gray-200 lg:border-0 lg:shadow-none bg-white lg:bg-transparent'>
            <nav className='lg:text-right text-center h-font f-normal'>
              <ul className='lg:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0'>
                {primaryMenu.map((menu, i) => (
                  <li key={i} className='px-4 border-b lg:border-b-0'>
                    {console.log(menu)}
                    {!/^#/.test(menu.path) && (
                      <TransitionLink
                        className='py-4 lg:py-0 block lg:inline-block'
                        to={menu.path}
                        onClick={e =>
                          console.log(
                            e.target.getAttribute('aria-current') !== null &&
                              e.target.getAttribute('aria-current')
                          )
                        }
                        exit={{
                          length: 1,
                          delay: 0,
                          appearAfter: 1,
                          zIndex: 2,
                          trigger: ({ exit, node }) => {
                            ptdrip({ exit, node, direction: 'out' })
                          }
                        }}
                        entry={{
                          length: 1,
                          delay: 0,
                          appearAfter: 1,
                          trigger: ({ exit, node }) => {
                            ptdrip({ exit, node, direction: 'in' })
                          }
                        }}
                      >
                        {menu.label}
                      </TransitionLink>
                    )}
                    {/^#/.test(menu.path) && (
                      <a
                        className='py-4 lg:py-0 block lg:inline'
                        href={menu.path}
                      >
                        {menu.label}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
        {/* Nav Menu */}
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
