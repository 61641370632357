/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { TransitionPortal } from 'gatsby-plugin-transition-link'
import { Helmet } from 'react-helmet'
import Header from './header'
import Footer from './footer'
// import '../assets/main.css'

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          href='https://fonts.googleapis.com/css2?family=Philosopher&family=Roboto:wght@300&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <Header />
      <TransitionPortal level='top'>
        <div className='ptportal-con h-font'>
          <div className='left-layer--1'></div>
          <div className='left-layer--3'>
            <h1 className='pt-text'>
              <p>
                <span></span>Web
              </p>
              <p>Solver</p>
              <p>
                Pro<span></span>
              </p>
            </h1>
          </div>
        </div>
      </TransitionPortal>
      <div className='fsd-main-content'>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
