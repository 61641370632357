import anime from 'animejs'

export const ptnewan = ({ exit, node, direction }) => {
  // const duration = direction === 'out' ? exit.length + exit.length / 4 : exit.length
  // const opacity = direction === 'in' ? 1 : 0
  // const scrollTop =
  //   (document.scrollingElement && document.scrollingElement.scrollTop) ||
  //   document.body.scrollTop ||
  //   (typeof window !== `undefined` && window.pageYOffset)

  // const holdPosition =
  //   direction === 'out'
  //     ? {
  //         overflowY: 'hidden',
  //         height: '100vh',
  //         scrollTop: scrollTop
  //       }
  //     : {}

  //   return new TimelineMax()
  //     .set(node, holdPosition)
  //     .fromTo(node, duration, { opacity: !opacity }, { opacity: opacity })
  //   return ()=>{
  //     anime.set(node, holdPosition)
  //     console.log('good')
  // anime.set(node, holdPosition)
  let fsd_long_axis =
    window.innerWidth > window.innerHeight
      ? window.innerWidth
      : window.innerHeight
  window.onresize = () => {
    fsd_long_axis =
      window.innerWidth > window.innerHeight
        ? window.innerWidth
        : window.innerHeight
  }

  let header = document.querySelector('.header-container')
  let main = document.querySelector('.tl-edges')

  !header.classList.contains('ptfixed') && header.classList.add('ptfixed')
  !main.classList.contains('ptmainfixed') && main.classList.add('ptmainfixed')
  // anime.set('body', {
  //   position: 'fixed',
  //   height: '100vh',
  //   left: 0,
  //   top: 0,
  //   overflow: 'hidden'
  // })

  let clip_path_dimention = `ellipse(${fsd_long_axis * 4}px ${fsd_long_axis *
    4}px  at 0% 0%)`

  anime.set('.ptportal-con', {
    opacity: 0
  })
  anime.set('.left-layer--1', {
    clipPath: 'ellipse(0vh 0vh at 0% 0%)'
  })
  anime.set('.left-layer--3', {
    clipPath: 'ellipse(0vh 0vh at 0% 0%)'
  })

  anime.set('.pt-text p:first-child span', {
    translateY: '10%',
    translateX: '-40%',
    rotate: '45deg',
    opacity: 0
  })
  anime.set(
    '.pt-text p:last-child span',
    {
      translateY: '20%',
      translateX: '47%',
      rotate: '45deg',
      opacity: 0
    } // start frame
  )
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
  const pt_timeline_drip = anime.timeline({
    easing: 'easeOutExpo',
    autoplay: false,
    loop: 1,
    easing: 'linear'
  })

  pt_timeline_drip
    .add({
      targets: '.ptportal-con',
      duration: 1,
      opacity: 1
    })
    .add(
      {
        targets: '.left-layer--1',
        duration: 2000,
        keyframes: [
          { clipPath: 'ellipse(0px 0px at 0% 0%)' },
          { clipPath: clip_path_dimention },
          { clipPath: clip_path_dimention },
          { clipPath: clip_path_dimention },
          { clipPath: 'ellipse(0px 0px at 0% 0%)' }
        ]
      },
      0
    )
    .add(
      {
        targets: '.left-layer--3',
        duration: 3000,
        keyframes: [
          { clipPath: 'ellipse(0px 0px at 0% 0%)' },
          { clipPath: clip_path_dimention },
          { clipPath: 'ellipse(0px 0px at 0% 0%)' }
        ]
      },
      '-=2600'
    )
    .add(
      {
        targets: '.pt-text p:first-child span',
        translateX: ['0%', '20%'],
        duration: 400,
        opacity: ['0', '1'],
        rotate: ['45deg', '45deg']
      },
      700
    )
    .add(
      {
        targets: '.pt-text p:last-child span',
        translateX: ['0%', '-30%'],
        duration: 470,
        opacity: ['0', '1'],
        rotate: ['45deg', '45deg']
      },
      900
    )
    .add({
      targets: 'body',
      duration: 1,
      complete: () => {
        console.log(`Animation is complete done`)
        // anime.set('body', {
        //   position: 'static',
        //   height: 'initial',
        //   left: 0,
        //   top: 0,
        //   overflow: 'visible'
        // })

        header.classList.contains('ptfixed') &&
          header.classList.remove('ptfixed')
        main.classList.contains('ptmainfixed') &&
          main.classList.remove('ptmainfixed')
        // window.scroll({
        //   top: 0,
        //   left: 0,
        //   behavior: 'smooth'
        // })
      }
    })
    .add({
      targets: '.ptportal-con',
      duration: 1,
      opacity: 0
    })

  pt_timeline_drip.play()
}
