import React, { useEffect, useState } from 'react'
// import Helmet from 'react-helmet'
import FacebookIcon from '../images/inlinesvgs/facebookicon.svg'
import TwitterIcon from '../images/inlinesvgs/twittericon.svg'
import LinkedinIcon from '../images/inlinesvgs/linkedinicon.svg'
import GithubIcon from '../images/inlinesvgs/githubicon.svg'
import MainulDipWSPOptimized from './../images/MainulDipWSPOptimized.jpg'
import SendingIcon from '../images/inlinesvgs/sendingicon.svg'
import ThumbsUp from '../images/inlinesvgs/wspthumbsup.svg'
import mediumZoom from 'medium-zoom'

function contactStatus (caller) {
  var thankYouMessage = document.querySelector('.contact_thankyou_message')
  if (caller === 'xhr') {
    if (thankYouMessage) {
      !thankYouMessage.classList.contains('active') &&
        thankYouMessage.classList.add('active')
    }
  }
  if (caller === 'btn') {
    if (thankYouMessage) {
      thankYouMessage.classList.toggle('active')
    }
  }
}
const Footer = () => {
  const [retry, setretry] = useState(0)
  useEffect(() => {
    mediumZoom('[data-zoomable-foot]', {
      background: '#000000',
      scrollOffset: 0
    })
    // get all data in form and return object
    function getFormData (form) {
      var elements = form.elements
      var sMessage
      var fields = Object.keys(elements)
        .filter(function (k) {
          if (elements[k].name === 'sMessage') {
            sMessage = elements[k].value
            return false
          }
          return true
        })
        .map(function (k) {
          if (elements[k].name !== undefined) {
            return elements[k].name
            // special case for Edge's html collection
          } else if (elements[k].length > 0) {
            return elements[k].item(0).name
          }
        })
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos && item
        })
      var formData = {}
      fields.forEach(function (name) {
        var element = elements[name]
        // singular form elements just have one value
        formData[name] = element.value
        // when our element has multiple items, get their values
        if (element.length) {
          var data = []
          for (var i = 0; i < element.length; i++) {
            var item = element.item(i)
            if (item.checked || item.selected) {
              data.push(item.value)
            }
          }
          formData[name] = data.join(', ')
        }
      })
      // add form-specific values into the data
      formData.formDataNameOrder = JSON.stringify(fields)
      formData.formGoogleSheetName = form.dataset.sheet || 'responses' // default sheet name
      formData.formGoogleSendEmail = form.dataset.email || '' // no email by default
      return { data: formData, sMessage: sMessage }
    }
    function handleFormSubmit (event) {
      event.preventDefault() // we are submitting via xhr below

      var form = event.target
      var formData = getFormData(form)
      var data = formData.data

      // If a sMessage field is filled, assume it was done so by a spam bot.
      if (formData.sMessage) {
        return false
      }
      var url =
        'https://script.google.com/macros/s/AKfycbxswF-6w3TB4BpUF0HOXMjuXd1fyck0PSOEC73PgxtAUP7fzv3m8Ct50VCgUehCeIWK/exec'
      var xhr = new XMLHttpRequest()
      xhr.open('POST', url)
      // xhr.withCredentials = true;
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          setretry(0)
          form.reset()
          contactStatus('xhr')
        } else if (
          xhr.status === 0 ||
          xhr.readyState === 0 ||
          xhr.status >= 500
        ) {
          setretry(1)
          contactStatus('xhr')
        }
      }
      // url encode form data for sending as post data
      var encoded = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        })
        .join('&')
      xhr.send(encoded)
    }
    function loaded () {
      // bind to the submit event of our form
      var forms = document.querySelectorAll('form.gform')
      for (var i = 0; i < forms.length; i++) {
        forms[i].addEventListener('submit', handleFormSubmit, false)
      }
    }
    loaded()
  }, [])
  return (
    <>
      <footer className='w-full'>
        <div className='footer-top py-4 lg:py-9'>
          <div className='flex flex-wrap text-left text-white'>
            {/* <!-- ABOUT COLUMN --> */}

            <div
              id='about'
              name='about'
              className='w-full lg:w-1/3 px-7 xl:px-12 mx-auto lg:ml-0'
            >
              <h4 className='mt-6 mb-3 text-center lg:text-left'>
                <span className='border-b border-gray-400 pb-1 px-4 lg:pl-0'>
                  About
                </span>
              </h4>
              <div className='mainuldipWSP mt-4 mb-6 w-full lg:w-11/12'>
                <img
                  className=''
                  src={MainulDipWSPOptimized}
                  alt='Photo of Mainul Dip'
                  data-zoomable-foot
                />
              </div>
              <p className='text-white'>
                <span>WebSolverPro</span> is a web design and app development
                agency, Created and maintain by{' '}
                <a href='https://github.com/mainuldip'>
                  Mainul Dip (Lead Developer)
                </a>
                . While designing and developing, we give our best effort with
                UI/UX best practices and performance (Speed, SEO, etc). We work
                with the most popular CMSs (WordPress, Shopify, etc) and
                well-maintained & secured open source projects.
              </p>
            </div>

            {/* <!-- FORM COLUMN --> */}

            <div
              id='message'
              className='footer-contact w-full lg:w-1/3 px-7 xl:px-12 mx-auto relative lg:ml-0'
            >
              <h4 className='mt-12 lg:mt-6 mb-3 text-center lg:text-left'>
                <span className='border-b border-gray-400 pb-1 px-4 lg:pl-0'>
                  Say "Hello <span className='text-yellow-300'>&#9787;</span>"
                </span>
              </h4>
              <p className=''>
                You can message directly with your issues or new ideas regarding
                Web Design and App Development. We will be happy to hear from
                you.
                <br />
              </p>
              {/* <p className='pt-2'>
                Best,
                <br />
                Mainul Dip
              </p> */}
              <form
                id='wspcontact'
                className='pt-6 pb-4 rounded gform'
                method='POST'
                data-email='websolverpro@gmail.com'
                action=''
              >
                <div className='flex items-center mb-4'>
                  <input
                    id='name'
                    name='name'
                    className='w-full h-10 p-2 pl-4 text-white'
                    type='text'
                    required
                    placeholder='Your Name'
                  />
                </div>
                <div className='flex items-center mb-4'>
                  <input
                    className='w-full h-10 p-2 pl-4 text-white'
                    id='email'
                    name='email'
                    type='email'
                    required
                    placeholder='Your Email'
                  />
                </div>
                <div className='mb-6'>
                  <textarea
                    form='wspcontact'
                    name='message'
                    className='w-full h-24 px-2 pl-4 pt-2 text-white'
                    placeholder='Your Message'
                    required
                  ></textarea>
                </div>
                <div className='hidden'>
                  <input id='sMessage' type='text' name='sMessage' value='' />
                </div>

                <div className='flex justify-between items-center'>
                  <button
                    type='submit'
                    form='wspcontact'
                    value='Submit'
                    className='wspcontact-button mx-auto py-2 px-4 bg-transparent text-white border-white border-2 font-bold flex justify-center rounded-3xl w-full'
                    // onClick={() => contactStatus('btn')}
                  >
                    <span className='sendingicon-con text-white inline-block transform scale-150 mr-2 self-center'>
                      <SendingIcon />
                    </span>
                    <span className='ml-3 text-xl'>Send</span>
                  </button>
                </div>
              </form>
              <div
                className='contact_thankyou_message'
                style={
                  !retry
                    ? { borderColor: '#61bdff' }
                    : { borderColor: '#ff6161' }
                }
              >
                <h4 className='message-text'>
                  {!retry
                    ? 'Thanks for contacting us! We will get back to you soon!'
                    : 'Could you please try again after a while!'}
                </h4>
                <button
                  type='button'
                  className='w-full  mx-auto text-black flex justify-center'
                  onClick={() => contactStatus('btn')}
                >
                  <span
                    className={`spn-out wspthumbsup self-end ${retry &&
                      'hidden'}`}
                  >
                    {!retry && <ThumbsUp />}
                  </span>
                  <span className='spn-out self-end'>
                    <span
                      className={`spn-inn py-2 px-4 rounded-3xl border-black border-2 text-xl  ml-4 w-full inline-block bg-white ${retry &&
                        'message-retry'}`}
                    >
                      {!retry ? 'Great' : 'OK'}
                    </span>
                  </span>
                </button>
              </div>
            </div>

            {/* <!-- CONTACTS COLUMN --> */}

            <div
              id='contact'
              className='w-full lg:w-1/3 px-7 xl:px-12 mx-auto lg:ml-0'
              // onClick={() => setretry(!retry)}
            >
              <h4 className='mt-12 lg:mt-6 mb-3 text-center lg:text-left'>
                <span className='border-b border-gray-400 pb-1 px-4 lg:pl-0'>
                  Contact
                </span>
              </h4>
              <p className='text-white pb-4'>
                Physically, we operate from Bangladesh maintaining some business
                hours overlap with America, Europe and Australia for our
                international clients. So, Please expect any response from us
                during GTM/UTC 9am to 8pm.
              </p>

              <p className='mb-1'>
                <span className='text-white inline-block transform -translate-y-0.5 scale-150 mr-1'>
                  &#9993;
                </span>{' '}
                Email :{` `}
                <a href='mailto:contact@websolverpro.com'>
                  contact@websolverpro.com
                </a>
              </p>

              <p className='mb-2'>
                <span className='text-white inline-block transform -translate-y-0.5 scale-125 mr-1'>
                  &#9742;
                </span>{' '}
                Phone :{` `}
                <a href='tel:+8801708746166'>
                  +8801708746166 ( GMT/UTC 9AM To 8PM )
                </a>
              </p>

              <p className='pt-2 pb-4'>
                <span className='text-white inline-block transform -translate-y-1 scale-150'>
                  &#9730;
                </span>{' '}
                3rd Floor, Kalims Tower, 41 Shahid Rafiq Road, Manikganj Sadar,
                P/O 1800, Dhaka, Bangladesh
              </p>
              <p className=' text-white mt-2 pb-4'>
                If conveienent, get in touch through our social media accounts.
              </p>
              <div className='wsp-social-icons flex justify-center lg:justify-start'>
                <div className='social-icon'>
                  <a
                    href='https://facebook.com/mainuldp'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FacebookIcon />
                  </a>{' '}
                </div>
                <div className='social-icon'>
                  <a
                    href='https://twitter.com/mainuldip'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <TwitterIcon />
                  </a>
                </div>
                <div className='social-icon'>
                  <a
                    href='https://linkedin.com/in/mainuldip'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <LinkedinIcon />
                  </a>
                </div>
                <div className='social-icon'>
                  <a
                    href='https://github.com/mainuldip'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <GithubIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='footer-bottom text-white text-center py-6 border-t border-gray-700'>
          © {new Date().getFullYear()}, All Rights Reserved By
          {` `} <a href='github.com/mainuldip'>Mainul Dip</a>
        </div>
      </footer>
    </>
  )
}

export default Footer
